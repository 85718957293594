import { ref } from 'vue'

export default function () {
  const showVModal = ref(false)

  const openVModal = () => {
    showVModal.value = true
    document.body.classList.add('no-scroll')
  }

  const closeVModal = () => {
    showVModal.value = false
    document.body.classList.remove('no-scroll')
  }

  const toggleVModal = () => {
    showVModal.value = !showVModal.value
    if (document.body.classList.contains('no-scroll')) {
      document.body.classList.remove('no-scroll')
    } else {
      document.body.classList.add('no-scroll')
    }
  }

  return {
    showVModal,
    openVModal,
    closeVModal,
    toggleVModal
  }
}
